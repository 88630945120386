import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    NgZone,
    HostListener,
    Input,
    OnDestroy,
    ElementRef,
    ViewChild
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    SocketService,
    EventEmitterService,
    DiagnosticService,
    AppService,
    UtilService,
    ProfileService,
    WaitingRoomService,
    UserService
} from 'src/app/core';
import { WebinarAttendeeService } from '../../../webinar-attendee/services/webinar-attendee.service';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { JmTranslateService } from 'src/app/core/services/jm-translate.service';
import { TranscriptDataService } from 'src/app/shared/services/transcript-data.service';

@Component({
    selector: 'app-webinar-transcription-panel',
    templateUrl: './webinar-transcription-panel.component.html',
    styleUrls: ['./webinar-transcription-panel.component.scss']
})
export class WebinarTranscriptionPanelComponent implements OnInit, OnDestroy {
    @Output() close: EventEmitter<any> = new EventEmitter();
    @ViewChild('callQnAView') callQnAMainView: ElementRef;
    @ViewChild('transcriptionContainer', { static: false }) transcriptionContainer: ElementRef;
    @Input() showTranscriptionView = false;
    @Input() sessionId: string;
    @Input() isMobileOS = false;

    tooltip = {
        transcriptDisclaimer:
            'This is an AI-generated translation, which may include some inaccuracies or variations in meaning.'
    };
    form: FormGroup;
    webinarId: string;
    loading = false;
    isMobileDevice: boolean;
    transcription: any[] = [];
    transcriptSubscription: Subscription;
    pollsviewHeight: string = '100%';
    remoteparticipant;
    profilePicUrls: string[] = [];
    waitingUsers: any = {};
    waitingUsersCont = 0;
    participantName;
    participantInitials;
    userScrolled = false;

    constructor(
        private fb: FormBuilder,
        private webinarService: WebinarAttendeeService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private zone: NgZone,
        private socketService: SocketService,
        private eventEmitterService: EventEmitterService,
        private diagnosticService: DiagnosticService,
        private appService: AppService,
        private utilService: UtilService,
        private callStateManagerService: CallStateManagerService,
        private googleTagManager: GoogleTagMangerService,
        private jmTranslateService: JmTranslateService,
        private transcriptDataService: TranscriptDataService,
        private profileService: ProfileService,
        private waitingRoomService: WaitingRoomService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.transcriptSubscription = this.transcriptDataService.transcription$.subscribe((data) => {
            if (data) {
                this.transcription = data;
                console.log('Received transcription data:', this.transcription);
                this.scrollToBottomIfNeeded();
            }
        });
        this.isMobileDevice = this.utilService.isMobileBrowser();
        this.webinarId = this.activatedRoute.parent.snapshot.params.shID;
        this.activatedRoute.queryParams.subscribe((params) => {
            this.sessionId = this.callStateManagerService.getSessionInfo()?.meetingId;
        });
        this.isMobileOS = this.utilService.isResponsiveMobileView(769);
        const keyboardListener = () => {
            const MIN_KEYBOARD_HEIGHT = 200; // Assuming the keyboard min height
            const isMobile = window.innerWidth < 768;
            const isKeyboardOpen =
                isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
            if (isKeyboardOpen && this.callQnAMainView) {
                this.callQnAMainView.nativeElement.scrollTop = this.callQnAMainView.nativeElement.scrollHeight;
            }
        };
        window.visualViewport.addEventListener('resize', keyboardListener);
    }

    scrollToBottom() {
        if (this.transcriptionContainer) {
            const container = this.transcriptionContainer.nativeElement;
            container.scrollTop = container.scrollHeight;
        }
    }

    scrollToBottomIfNeeded() {
        if (this.transcriptionContainer) {
            const container = this.transcriptionContainer.nativeElement;
            const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight <= 10; // Adjust buffer
            if (isAtBottom) {
                this.scrollToBottom();
            }
        }
    }

    onScroll() {
        if (this.transcriptionContainer) {
            const container = this.transcriptionContainer.nativeElement;
            const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight <= 10; // Adjust buffer
            this.userScrolled = !isAtBottom; // Set userScrolled to true if they scroll up
        }
    }

    ngAfterViewChecked() {
        if (!this.userScrolled) {
            this.scrollToBottom();
        }
    }

    closeView() {
        this.close.emit();
    }

    ngOnDestroy() {
        // Emit event to show unseen answer count when closing
        this.eventEmitterService.emit({
            type: 'SHOW_UNSEEN_ANSWER_COUNT',
            data: true
        });

        if (this.transcriptSubscription) {
            this.transcriptSubscription.unsubscribe();
        }
    }
}
