import { Component, Input, OnInit } from '@angular/core';
import { JmTranslateService } from 'src/app/core/services/jm-translate.service';
import { TranscriptDataService } from '../../services/transcript-data.service';

@Component({
    selector: 'app-webinar-closed-captions',
    templateUrl: './webinar-closed-captions.component.html',
    styleUrls: ['./webinar-closed-captions.component.scss']
})
export class WebinarClosedCaptionsComponent implements OnInit {
    captionText; // Not using this as of now.
    @Input() isAttendee;

    constructor(public jmTranslateService: JmTranslateService, public transcriptDataService: TranscriptDataService) {}

    ngOnInit() {
        this.captionText = this.jmTranslateService.captionText;
    }
}
