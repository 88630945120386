import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class SuperControlAuthService {
    private isSuperAdminUser;
    constructor(private authService: AuthService, private utilService: UtilService) {}

    setSuperAdminAuth(authToken) {
        this.authService.setSuperAdminAuthInfo({ superJwt: authToken });
    }

    validateSuperAdminUser(authToken) {
        const superAdminUserInfo = this.utilService.parseJWT(authToken);
        const currentUserInfo = this.utilService.parseJWT(this.authService.getAuthInfo()?.jwt);

        if (superAdminUserInfo.userId === currentUserInfo.userId) {
            return true;
        }
        return false;
    }

    setSuperAdminUser(boolean) {
        this.isSuperAdminUser = boolean;
    }

    getIsSuperAdminUser() {
        return this.isSuperAdminUser;
    }
}
