export const WEBINAR_ATTENDEE_EVENTS = {
    ACTIVATE_WEBINAR: 'ACTIVATE_WEBINAR',
    SHOW_POLL: 'SHOW_POLL',
    SHOW_QA: 'SHOW_QA',
    SHOW_TRANSCRIPTION: 'SHOW_TRANSCRIPTION',
    SHOW_WEBINAR_COMMENTS: 'SHOW_WEBINAR_COMMENTS',
    SHOW_LEADERBOARD: 'SHOW_LEADERBOARD',
    SHOW_TICKER_PANEL: 'SHOW_TICKER_PANEL',
    SHOW_QA_HOST: 'SHOW_QA_HOST',
    UPDATE_TICKER: 'UPDATE_TICKER',
    MULTIPLE_QUES_ERR_MODAL: 'MULTIPLE_QUES_ERR_MODAL',
    MANAGE_RESOLUTION: 'MANAGE_RESOLUTION',
    REACTIONS_MODAL: 'REACTIONS_MODAL',
    GUEST_ATTENDEE: 'GUEST_ATTENDEE',
    GUEST_ATTENDEE_AUTH_INFO: 'GUEST_ATTENDEE_AUTH_INFO',
    SHOW_UNSEEN_ANSWER_COUNT: 'SHOW_UNSEEN_ANSWER_COUNT',
    UNSEEN_ANSWER_COUNT: 'UNSEEN_ANSWER_COUNT',
    LEAVE_WEBINAR: 'LEAVE_WEBINAR',
    UPDATE_LIVE_POLL_COUNT: 'UPDATE_LIVE_POLL_COUNT',
    SHOW_SCHEDULE: 'SHOW_SCHEDULE',
    HIDE_SCHEDULE: 'HIDE_SCHEDULE',
    PLAY_LIVE_FEEDS: 'PLAY_LIVE_FEEDS',
    SHOW_WEBINAR_LIVE_CHAT: 'SHOW_LIVE_CHAT',
    SHOW_GAMES: 'SHOW_GAMES'
};
