import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProfileService } from 'src/app/core';
import { SpeechRecognitionService } from 'src/app/core/services/speech-recognition.service';
import { AppService } from 'src/app/core/services/app.service';

@Injectable({
    providedIn: 'root'
})
export class TranscriptDataService implements OnDestroy {
    enableTranscription: boolean = false;
    private transcriptionSubscription: Subscription | null = null;
    private transcriptions: {
        text: string;
        userId: string;
        userName: string;
        startTime: number;
        endTime: number;
        userProfilePic: boolean;
        userProfilePicPath: string | null;
        bubbleStartTime?: number;
    }[] = [];
    lastTranscriptText = '';

    private transcriptionSource = new BehaviorSubject<
        {
            text: string;
            userId: string;
            userName: string;
            startTime: number;
            endTime: number;
            userProfilePic: boolean;
            userProfilePicPath: string | null;
        }[]
    >([]);

    transcription$ = this.transcriptionSource.asObservable();
    isClosedCaptionEnabledSubject = new BehaviorSubject<boolean>(false);
    isClosedCaptionEnabled$ = this.isClosedCaptionEnabledSubject.asObservable();

    constructor(
        private speechRecognitionService: SpeechRecognitionService,
        private profileService: ProfileService,
        private appService: AppService,
        private zone: NgZone
    ) {}

    ngOnDestroy() {
        if (this.transcriptionSubscription) {
            this.transcriptionSubscription.unsubscribe();
        }
    }

    handleLocalSpeakerTranscription() {
        if (this.transcriptionSubscription) {
            return;
        }

        this.transcriptionSubscription = this.speechRecognitionService
            .getSpeechTranscriptUpdates()
            .subscribe((transcriptionData: any) => {
                this.processIncomingTranscription(transcriptionData);
            });
    }

    processIncomingTranscription(transcriptionData: {
        transcription: string;
        userId: string;
        userName: string;
        startTime: number;
        endTime: number;
        userProfilePic?: boolean;
    }) {
        try {
            const { transcription, userId, userName, startTime, endTime } = transcriptionData;
            this.lastTranscriptText = transcription;
            const userProfilePic = transcriptionData.userProfilePic || false;
            const userProfilePicPath = userProfilePic ? this.profileService.getProfilePicPath(userId) : null;

            const lastUserTranscriptionIndex = this.findLastTranscriptionIndex(userId);

            if (lastUserTranscriptionIndex !== -1) {
                const lastUserTranscription = this.transcriptions[lastUserTranscriptionIndex];

                if (!lastUserTranscription.bubbleStartTime) {
                    lastUserTranscription.bubbleStartTime = lastUserTranscription.startTime;
                }

                const timeDifference = startTime - lastUserTranscription.bubbleStartTime;

                if (timeDifference < 60000) {
                    lastUserTranscription.text += `. ${transcription}`;
                    lastUserTranscription.endTime = endTime;
                } else {
                    this.addNewTranscription(
                        transcription,
                        userId,
                        userName,
                        startTime,
                        endTime,
                        userProfilePic,
                        userProfilePicPath
                    );
                }
            } else {
                this.addNewTranscription(
                    transcription,
                    userId,
                    userName,
                    startTime,
                    endTime,
                    userProfilePic,
                    userProfilePicPath
                );
            }

            this.transcriptionSource.next(this.transcriptions);
        } catch (error) {
            console.error('Error processing transcription:', error);
        }
    }

    private addNewTranscription(
        transcription: string,
        userId: string,
        userName: string,
        startTime: number,
        endTime: number,
        userProfilePic: boolean,
        userProfilePicPath: string | null
    ) {
        this.transcriptions.push({
            text: transcription,
            userId,
            userName,
            startTime,
            endTime,
            userProfilePic,
            userProfilePicPath,
            bubbleStartTime: startTime
        });
    }

    private findLastTranscriptionIndex(userId: string): number {
        for (let i = this.transcriptions.length - 1; i >= 0; i--) {
            if (this.transcriptions[i].userId === userId) {
                return i;
            }
        }
        return -1;
    }

    toggleCcEnabled(enabled: boolean): void {
        this.isClosedCaptionEnabledSubject.next(enabled);
    }

    clearTranscriptions(): void {
        this.transcriptions = [];
        this.transcriptionSource.next([]);
    }

    setTranscriptionFeature(state: boolean) {
        this.enableTranscription = state;
    }
}
