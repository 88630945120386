export const TRANSLATE_CONSTANTS = {
    translateLanguages: [
        {
            text: 'English',
            value: 'English',
            languageCode: 'en',
            countryCode: 'US',
            checked: false
        },
        {
            text: 'हिंदी',
            value: 'Hindi',
            languageCode: 'hi',
            countryCode: 'IN',
            checked: true
        },
        {
            text: 'తెలుగు',
            value: 'Telugu',
            languageCode: 'te',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'বাংলা',
            value: 'Bengali',
            languageCode: 'bn',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'ગુજરાતી',
            value: 'Gujarati',
            languageCode: 'gu',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'मराठी',
            value: 'Marathi',
            languageCode: 'mr',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'ಕನ್ನಡ ',
            value: 'Kannada',
            languageCode: 'kn',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'മലയാളം',
            value: 'Malayalam',
            languageCode: 'ml',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'தமிழ்',
            value: 'Tamil',
            languageCode: 'ta',
            countryCode: 'IN',
            checked: false
        }
    ],
    transcriptLanguages: [
        {
            text: 'English',
            value: 'English',
            languageCode: 'en',
            countryCode: 'US',
            checked: false
        },
        {
            text: 'हिंदी',
            value: 'Hindi',
            languageCode: 'hi',
            countryCode: 'IN',
            checked: true
        },
        {
            text: 'తెలుగు',
            value: 'Telugu',
            languageCode: 'te',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'বাংলা',
            value: 'Bengali',
            languageCode: 'bn',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'ગુજરાતી',
            value: 'Gujarati',
            languageCode: 'gu',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'मराठी',
            value: 'Marathi',
            languageCode: 'mr',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'ಕನ್ನಡ ',
            value: 'Kannada',
            languageCode: 'kn',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'മലയാളം',
            value: 'Malayalam',
            languageCode: 'ml',
            countryCode: 'IN',
            checked: false
        },
        {
            text: 'தமிழ்',
            value: 'Tamil',
            languageCode: 'ta',
            countryCode: 'IN',
            checked: false
        }
    ]
};
