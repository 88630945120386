import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
    transform(timestamp: number): string {
        if (isNaN(timestamp) || timestamp <= 0) {
            return '';
        }
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
}
