import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './../services/auth.service';
import { AppService, UserService } from '../services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuperControlAuthService } from '../services/super-control-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private appService: AppService,
        private superControlAuthService: SuperControlAuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        if (this.authService.getIsAuthenticated()) {
            return this.hasProfile();
        }
        if (this.superControlAuthService.getIsSuperAdminUser()) {
            sessionStorage.setItem('returnUrl', snapshot.url);
            this.router.navigate(['/login']);
            return of(false);
        }
        if (route?.data?.handleRedirection) {
            sessionStorage.setItem('returnUrl', snapshot.url);
            this.router.navigate(['/login']);
            return of(false);
        }
        if (route?.data?.handleRedirectionOnQueryParamCheck && route.queryParams?.native === 'true') {
            sessionStorage.setItem('returnUrl', snapshot.url);
            this.router.navigate(['/login']);
            return of(false);
        }
        if (this.appService.isJioEvents() && this.appService.isJioEventsRedirectionEnabled()) {
            window.open(this.appService.getConfigVariable('JIO_EVENTS_REDIRECTION'), '_self');
        } else {
            this.router.navigate(['/home']);
        }
        return of(false);
    }

    hasProfile() {
        return this.userService.getUser().pipe(
            map((res) => {
                return true;
            })
        );
    }
}
