import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { TRANSLATE_CONSTANTS } from 'src/app/constants/translate-constants';
import { JmMediaService } from 'src/app/core/services/jm-media.service';
import { JmTranslateService } from 'src/app/core/services/jm-translate.service';
import { TranscriptDataService } from 'src/app/shared/services/transcript-data.service';

@Component({
    selector: 'app-webinar-translation-controls',
    templateUrl: './webinar-translation-controls.component.html',
    styleUrls: ['./webinar-translation-controls.component.scss']
})
export class WebinarTranslationControlsComponent implements OnInit {
    @Output() closePopupEmitter = new EventEmitter();
    isTranslateEnabled: any;
    isRemoteAudioDisabled: boolean = false;
    isTranslating: boolean = false;
    translateLanguages;
    transcriptLanguages;
    isAudioTranslationAndCcEnabled: boolean = false;
    isClosedCaptionEnabled: boolean = false;
    private captionCheck: Subscription;

    constructor(
        private jmTranslateService: JmTranslateService,
        private jmMediaService: JmMediaService,
        private transcriptDataService: TranscriptDataService
    ) {}

    ngOnInit(): void {
        this.getTranslateLanguages();
        this.isTranslateEnabled = this.jmTranslateService.enableAudioTranslation;
        this.isRemoteAudioDisabled = this.jmMediaService.originalAudioTracksMute;
        this.captionCheck = this.transcriptDataService.isClosedCaptionEnabled$.subscribe((isEnabled) => {
            this.isClosedCaptionEnabled = isEnabled;
        });
    }

    getTranslateLanguages() {
        this.translateLanguages = TRANSLATE_CONSTANTS.translateLanguages;
        this.transcriptLanguages = TRANSLATE_CONSTANTS.transcriptLanguages;
    }

    toggleTranslateButton() {
        if (this.isTranslateEnabled) {
            this.isTranslateEnabled = false;
            this.jmTranslateService.setTranslationStatus(false);
            this.jmTranslateService.stopListening();
        } else {
            this.isTranslateEnabled = true;
            this.jmTranslateService.setTranslationStatus(true);
        }
    }

    toggleOriginalAudio() {
        this.jmMediaService.toggleOriginalTracksMuteState();
    }

    toggleCcEnabled() {
        this.isClosedCaptionEnabled = !this.isClosedCaptionEnabled;
        this.transcriptDataService.toggleCcEnabled(this.isClosedCaptionEnabled);
    }

    selectLanguage(selectedLanguage, isTranslate = false) {
        // Check if translation is enabled
        if (this.isTranslateEnabled) {
            if (isTranslate) {
                // Translation mode
                // Check if the first language in TranscriptLanguages is not checked
                if (!this.transcriptLanguages[0].checked) {
                    // Update both TranscriptLanguages and TranslateLanguages
                    this.updateLanguages(selectedLanguage, this.transcriptLanguages);
                    this.updateLanguages(selectedLanguage, this.translateLanguages);
                } else {
                    // Update only TranslateLanguages
                    this.updateLanguages(selectedLanguage, this.translateLanguages);
                }
                // Set translating flag
                this.isTranslating = true;
            } else {
                // Transcript mode
                // Check if translating is true
                if (this.isTranslating) {
                    // Find the selected translation language in TranslateLanguages
                    let selectedTranslate = this.transcriptLanguages.find(
                        (language) => language.value === selectedLanguage.value
                    );

                    if (selectedTranslate && selectedTranslate.checked) {
                        // Update TranscriptLanguages only if selected translation is checked
                        this.updateLanguages(selectedLanguage, this.transcriptLanguages);
                    }
                } else {
                    // If not translating, do nothing
                    return;
                }
            }
            // Update jmTranslateService with the selected language value
            this.jmTranslateService.selectedLanguage = selectedLanguage.value;
        }
    }

    updateLanguages(selectedLanguage: { id: string; value: string }, languageArray) {
        // Iterate through the language array
        languageArray.forEach((language) => {
            // Set checked state based on selectedLanguage
            language.checked = language.value === selectedLanguage.value;

            // Update corresponding radio button in the DOM
            let radioButton = document.getElementById(language.id) as HTMLInputElement;
            if (radioButton) {
                radioButton.checked = language.checked;
            }
        });
    }

    closePopup() {
        this.closePopupEmitter.emit();
    }
}
