<div class="call-transcription">
    <div class="call-options-header">
        <div class="title">
            <div class="heading">Transcription</div>
            <app-info-icon
                [tooltip]="tooltip.transcriptDisclaimer"
                style="margin-top: 5px; margin-left: 8px"
            ></app-info-icon>
        </div>
        <div (click)="closeView()" class="close-icon pointer" id="translateClosedBtnToggle"></div>
    </div>

    <app-loader *ngIf="loading"></app-loader>

    <div class="body" *ngIf="!loading" [class.isMobile]="isMobileOS">
        <div *ngIf="!transcription || transcription.length === 0" class="default-transcription-screen">
            <div class="no-questions-image text-center mb-3"></div>
            <div class="title text-center mb-3">
                Transcription will appear here <br />
                when someone starts speaking
            </div>
        </div>

        <div
            #transcriptionContainer
            class="transcription-bubbles"
            *ngIf="transcription && transcription.length > 0"
            (scroll)="onScroll()"
        >
            <div class="transcription-row" *ngFor="let transcription of transcription">
                <div class="speaker">
                    <div class="profile-name">
                        <ng-container *ngIf="transcription.userProfilePic; else initials">
                            <img
                                [src]="transcription.userProfilePicPath"
                                alt="{{ transcription.userName }}"
                                class="card-profile rounded-circle"
                            />
                        </ng-container>
                        <ng-template #initials>
                            <div
                                class="card-profile rounded-circle"
                                appAvatarWithInitials
                                [name]="transcription?.userName"
                                [userId]="transcription?.userId"
                            ></div>
                        </ng-template>
                        <span class="userName"> {{ transcription.userName }}</span>
                    </div>
                    <span class="time">{{ transcription.startTime | formatTime }}</span>
                </div>
                <div class="message" [innerText]="transcription.text"></div>
            </div>
        </div>
    </div>
</div>
