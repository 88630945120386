<div class="share-media-options-wrapper">
    <div class="share-media-container-header">
        <div>
            <div>Change Language</div>
            <div>Select options to experience event in your own language</div>
        </div>
        <img class="pointer" src="assets/images/close_icon_opaque.svg" (click)="closePopup()" id="shareCloseTabIcon" />
    </div>
    <div>
        <input type="checkbox" id="enableTranslate" (click)="toggleTranslateButton()" [checked]="isTranslateEnabled" />
        Enable Translating
    </div>
    <div>
        <input
            type="checkbox"
            id="muteOriginalAudio"
            (click)="toggleOriginalAudio()"
            [checked]="isRemoteAudioDisabled"
        />
        Mute Original Audio
    </div>
    <div>
        <input
            type="checkbox"
            id="isAudioTranslationAndCcEnabled"
            (click)="toggleCcEnabled()"
            [checked]="isClosedCaptionEnabled"
        />
        Closed Captions
    </div>

    <div class="dual-option" [class.disabled]="!isTranslateEnabled">
        <div class="">
            <div class="audio-header">Audio and CC language</div>
            <div class="audio-language">
                <ng-container *ngFor="let language of translateLanguages; let i = index">
                    <div class="language-wrapper">
                        <input
                            type="radio"
                            id="{{ language.languageCode }}"
                            name="translate"
                            [checked]="language.checked"
                            (click)="selectLanguage(language, true)"
                        />
                        <label for="{{ language.languageCode }}">{{ language.text }}</label
                        ><br />
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
