import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import * as audioBufferToWav from 'audiobuffer-to-wav';
import { environment } from 'src/environments/environment';
import { AppLoggerService } from './app-logger.service';
import VAD from '../../webinar-attendee/services/vad';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class JmTranslateService {
    TRANSLATE_DOMAIN = 'https://sit.translate.jio/';
    enableJmTranslateService: boolean = false;
    mediaRecorder: any;
    wasEnabled = false;
    selectedLanguage: 'Hindi' | 'English' | 'Telugu' | 'Tamil' | 'Gujarati' | 'Marathi' = 'Hindi';
    captionText = '';
    packetSendInterval;
    audioChunks = [];
    ambientNoiseLevel = null;
    audioStream: MediaStream;
    jmAudioTrack: MediaStreamTrack;
    vadInstance;
    sourceTrack: any;
    jmAuthInfo: any;
    transcriptionsToProcess: any = [];
    renderingTranslation: any;
    enableAudioTranslation: any = false;
    enableClosedCaptions: any = false;

    constructor(
        private restService: RestService,

        private appLoggerService: AppLoggerService,
        private authService: AuthService,
        private appService: AppService
    ) {
        if (environment.BASE_URL.includes('localhost')) {
            this.TRANSLATE_DOMAIN = '';
        }
    }

    async requestJmAuthInfo() {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('x-source', 'jioevents');
        this.jmAuthInfo = await this.restService
            .get(`/api/jiotranslate/token${this.authService.getIsAuthenticated() ? '' : '/guest'}`, { headers })
            .toPromise();
    }

    directTranslate(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/direct-translate`, payload);
    }

    translatorSTT(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/stt`, payload);
    }

    translatorTTS(payload) {
        return this.restService.post(`${this.TRANSLATE_DOMAIN}translator/tts`, payload);
    }

    isTranslateUrl(url: string) {
        return url.indexOf('translator') > -1;
    }

    async checkForAuthInfo() {
        if (!this.jmAuthInfo) {
            return this.requestJmAuthInfo();
        }
    }

    getToken() {
        return this.jmAuthInfo?.jwt;
    }

    async trimSilence(audioBuffer) {
        const audioData = audioBuffer.getChannelData(0);
        const silenceThreshold = 0.01;
        let endIndex = audioData.length - 1;
        for (let i = audioData.length - 1; i >= 0; i--) {
            if (Math.abs(audioData[i]) > silenceThreshold) {
                endIndex = i;
                break;
            }
        }
        const duration = endIndex / audioBuffer.sampleRate;
        const audioContext = new AudioContext();
        const newBuffer = audioContext.createBuffer(
            audioBuffer.numberOfChannels,
            Math.ceil(duration * audioBuffer.sampleRate),
            audioBuffer.sampleRate
        );
        for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
            const newData = audioBuffer
                .getChannelData(channel)
                .subarray(0, Math.ceil(duration * audioBuffer.sampleRate));
            newBuffer.copyToChannel(newData, channel);
        }
        return newBuffer;
    }
    async convertTo44KAudioData(audioData, sourceSampleRate, targetSampleRate) {
        const audioContext = new OfflineAudioContext({
            numberOfChannels: audioData.numberOfChannels,
            length: audioData.length,
            sampleRate: targetSampleRate
        });
        const audioBuffer = audioContext.createBuffer(audioData.numberOfChannels, audioData.length, sourceSampleRate);
        audioBuffer.copyToChannel(audioData.getChannelData(0), 0);
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);
        const renderedBuffer = await audioContext.startRendering();
        return renderedBuffer;
    }
    wavBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                let base64data = reader.result;
                if (base64data.split(',').length > 1) {
                    base64data = base64data.split(',')[1];
                    resolve(base64data);
                } else {
                    console.log('Error while splitting base64', base64data);
                }
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    stopListening() {
        if (!this.wasEnabled) {
            return;
        }
        this.audioStream = null;
        this.sourceTrack = null;
        this.jmAudioTrack = null;
        this.mediaRecorder.stop();
        if (this.packetSendInterval) {
            clearInterval(this.packetSendInterval);
        }
        this.wasEnabled = false;
    }

    detectVoiceVad() {
        let audioContext = new AudioContext();
        let source = audioContext.createMediaStreamSource(this.audioStream);
        let options = {
            source: source
        };

        // Create VAD
        this.vadInstance = new VAD(options);
        this.vadInstance.options.voiceStater.on('VOICE_START', () => {
            this.mediaRecorder.start();
            this.appLoggerService.log('Received Voice start');
        });
        this.vadInstance.options.voiceStater.on('VOICE_END', (event) => {
            this.appLoggerService.log('Received Voice end');
            this.mediaRecorder.stop();
        });
    }

    async detectVolumeDrops() {
        try {
            this.detectVoiceVad();
        } catch (err) {
            this.appLoggerService.log('Error occured when tried to detect volume drops');
        }
    }

    startTranslating(jmAudioTrack: any) {
        if (this.jmAudioTrack && this.audioStream) {
            this.appLoggerService.log('Already translating a track');
            return;
        }
        this.sourceTrack = jmAudioTrack;
        this.jmAudioTrack = jmAudioTrack.getMediaStreamTrack();
        this.audioStream = new MediaStream([this.jmAudioTrack]);
        this.detectVolumeDrops();
        this.mediaRecorder = new MediaRecorder(this.audioStream);
        this.mediaRecorder.ondataavailable = (event) => {
            console.log('jm audio chunk pushing', event);
            this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
            console.log('Media recorder stopped');
            this.convertAudioChunks(this.audioChunks);
        };
        this.wasEnabled = true;
        // this.mediaRecorder.start();
        // this.sendDataInPackets();
    }

    sendDataInPackets() {
        this.packetSendInterval = setInterval(() => {
            this.wasEnabled = true;
            this.mediaRecorder.stop();
            this.mediaRecorder.start();
        }, 5000);
    }

    convertAudioChunks(audioChunkData) {
        if (audioChunkData.length > 0) {
            console.log('jm audio blob creating', audioChunkData);
            const audioBlob = new Blob(audioChunkData, { type: 'audio/webm' });
            this.audioChunks = [];
            console.log('jm audio blob', audioBlob);
            this.convertToWavAndSend(audioBlob);
        }
    }

    async translateSpeechToText(payload) {
        try {
            await this.checkForAuthInfo();
            const data = (await this.translatorSTT(payload).toPromise()) as any;
            console.log('STT - Translate data:', data);

            if (data?.status === 'success') {
                console.log('Recognized text:', data.recognized_text);
                const translatedText = await this.initiateTTT(data.recognized_text);
                this.captionText = translatedText;
                const translatedAudioContent = await this.getTranslatedAudioTrack('destination', translatedText);
                this.playAudio(translatedAudioContent);
            } else {
                console.error('Audio unclear. Please try again');
            }
        } catch (error) {
            console.error('STT - Error fetching translation:', error);
        }
    }

    convertToWavAndSend(audioBlob: Blob) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const arrayBuffer = fileReader.result as ArrayBuffer;
            const audioContext = new AudioContext();
            audioContext
                .decodeAudioData(arrayBuffer)
                .then(async (audioBuffer) => {
                    // Converting to 16000Hz if it crosses 16000Hz
                    console.log('Before Convert Sample Rate:', audioBuffer.sampleRate);
                    if (audioBuffer?.sampleRate > 16000) {
                        audioBuffer = await this.convertTo44KAudioData(audioBuffer, audioBuffer.sampleRate, 16000);
                        audioBuffer = await this.trimSilence(audioBuffer);
                    }
                    console.log('After convert Sample Rate:', audioBuffer?.sampleRate);

                    const wavBuffer = audioBufferToWav.default(audioBuffer);
                    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
                    this.wavBlobToBase64(wavBlob)
                        .then((base64EncodedAudio) => {
                            const payload = {
                                platform: 'google',
                                config: {
                                    sampleRateHertz: 48000,
                                    encoding: 'LINEAR16',
                                    language: 'English'
                                },
                                audio: { content: base64EncodedAudio }
                            };
                            this.translateSpeechToText(payload);
                        })
                        .catch((error) => {
                            console.error('Error converting audio to Base64:', error);
                        });
                })
                .catch((error) => {
                    console.error('Error decoding audio:', error);
                });
        };
        fileReader.readAsArrayBuffer(audioBlob);
    }

    async processIncomingTranscription(text: string) {
        try {
            console.log('Incoming transcription:', text);
            const incomingTranscriptStatus = {
                id: Date.now(),
                text,
                tttComplete: false, // text to text translation
                ttsComplete: false, // text to speech conversion
                translatedText: '',
                translatedAudioContent: ''
            };
            this.transcriptionsToProcess.push(incomingTranscriptStatus);

            const translatedText = await this.initiateTTT(incomingTranscriptStatus.text);
            incomingTranscriptStatus.tttComplete = true;
            incomingTranscriptStatus.translatedText = translatedText;

            const translatedAudioContent = await this.getTranslatedAudioTrack('destination', translatedText);
            incomingTranscriptStatus.ttsComplete = true;
            incomingTranscriptStatus.translatedAudioContent = translatedAudioContent;

            return this.processEarliestTranscription();
        } catch (err) {
            console.error('Error processing incoming transcription:', err);
        }
    }

    async processEarliestTranscription() {
        if (this.renderingTranslation) return;
        if (this.transcriptionsToProcess.length <= 0) return;

        const earliestTranscription = this.transcriptionsToProcess[0];
        if (!earliestTranscription.tttComplete || !earliestTranscription.ttsComplete) return;

        this.transcriptionsToProcess.shift();
        await this.publishCCandTranslatedAudio(earliestTranscription);
        return this.processEarliestTranscription();
    }

    async publishCCandTranslatedAudio(transcription) {
        try {
            if (!transcription.text || !transcription.translatedAudioContent) return;
            this.renderingTranslation = true;

            this.appLoggerService.log('Publishing CC and Translated Audio:', transcription);
            this.appLoggerService.log('CC:', transcription.text);
            this.appLoggerService.log('Translated Text:', transcription.translatedText);

            this.captionText = transcription.translatedText;
            await this.playAudio(transcription.translatedAudioContent);

            this.renderingTranslation = false;
        } catch (error) {
            this.appLoggerService.log('Error publishing CC and Translated Audio:', error);
        } finally {
            this.renderingTranslation = false;
        }
    }

    async initiateTTT(text: string) {
        if (!text || text === '') return;

        await this.checkForAuthInfo();
        const payload = this.createTranslationPayload(text);
        console.log('TTS - Translation payload:', payload);

        try {
            const data = await this.directTranslate(payload).toPromise();
            console.log('TTS - Translate data:', data);
            return this.handleTranslationResponse(data);
        } catch (error) {
            console.error('TTS - Error fetching translation:', error);
        }
    }

    private createTranslationPayload(text: string) {
        return {
            platform: 'google',
            q: text,
            source_language: 'English',
            target_language: this.selectedLanguage
        };
    }

    private handleTranslationResponse(data: any) {
        if (data?.status === 'success') {
            return data.translated_text;
        } else {
            console.log('Audio unclear. Please try again');
            return null;
        }
    }

    async convertTextToSpeech(payload, from) {
        try {
            await this.checkForAuthInfo();
            return this.translatorTTS(payload).toPromise() as any;
        } catch (error) {
            console.error('TTS - Error fetching translation:', error);
        }
    }

    async playAudio(audioContent): Promise<void> {
        try {
            const audio = new Audio(`data:audio/wav;base64,${audioContent}`);
            return new Promise<void>((resolve, reject) => {
                audio.onended = () => resolve();
                audio.onerror = (error) => reject(error);
                audio.play().catch(reject);
            });
        } catch (error) {
            console.error('Error playing TTS audio: ', error);
        }
    }

    async getTranslatedAudioTrack(from, text) {
        try {
            const payload = this.getTTSPayload(text);
            const data = await this.convertTextToSpeech(payload, from);
            return this.handleTTSResponse(data, from);
        } catch (error) {
            console.error('Error in playTrack:', error);
        }
    }

    private handleTTSResponse(data: any, from: string) {
        if (!data?.audioContent) {
            console.error('TTS - No audio content received');
            return;
        } else {
            return data.audioContent;
        }
    }

    getTTSPayload(text: string) {
        return {
            platform: 'google',
            input: {
                text: text
            },
            audioConfig: {
                encoding: 'LINEAR16'
            },
            language: this.selectedLanguage,
            gender: 'male'
        };
    }

    toggleRemoteAudio(mute: boolean) {
        if (!this.sourceTrack) {
            this.appLoggerService.log('No Remote Audio Track Reference');
            return;
        }
        try {
            this.sourceTrack.setVolume(mute ? 0 : 1);
        } catch (err) {
            this.appLoggerService.log('ToggleRemoteAudio: Error occured ', err);
        }
    }

    setTranslationFeature(state) {
        this.setTranslateDomain();
        this.enableJmTranslateService = state;
    }

    setTranslateDomain() {
        this.TRANSLATE_DOMAIN = this.appService.getConfigVariableWithDefault(
            'TRANSLATE_DOMAIN',
            'https://sit.translate.jio/'
        );
    }

    setTranslationStatus(state) {
        this.enableAudioTranslation = state;
    }
}
