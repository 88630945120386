<div class="captions" [class.attendee-cap]="isAttendee">
    <div class="cc-image"><img src="assets/icons/Tagcc.svg" alt="" /></div>
    <div
        class="translate-text"
        [innerText]="
            jmTranslateService.enableAudioTranslation
                ? jmTranslateService.captionText
                : transcriptDataService.lastTranscriptText
        "
    ></div>
</div>
