import { Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WebinarConferenceService } from 'src/app/conference/services';
import { ConfirmationService } from 'primeng/api';
import { APP_EVENTS, INSIDE_EVENT, MEDIA_ENGINE_EVENTS } from 'src/app/constants';
import {
    AppService,
    DiagnosticService,
    EventEmitterService,
    RoomConnectionService,
    RtcService,
    UserService,
    UtilService,
    VideoWrapperService
} from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { LeaderboardService } from 'src/app/core/services/leaderboard.service';
import { EventsPlusService } from 'src/app/core/services/events-plus.service';
import { SpeechRecognitionService } from 'src/app/core/services/speech-recognition.service';
import { JmTranslateService } from 'src/app/core/services/jm-translate.service';
import { TranscriptDataService } from './transcript-data.service';

@Injectable({
    providedIn: 'root'
})
export class MediaOrchestratorService {
    meetingObj: any;
    videoService: any;
    gtmMeetingInfo: any;
    showStreamMixerPreviewPopup: boolean;
    showCustomVideoFeedSettings: boolean;
    destroyResizeVideoComponent: boolean;
    constructor(
        private videoWrapperService: VideoWrapperService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private eventEmitterService: EventEmitterService,
        private appLoggerService: AppLoggerService,
        private roomConnectionService: RoomConnectionService,
        private diagnosticService: DiagnosticService,
        private webinarConfService: WebinarConferenceService,
        private callViewStateManagerService: CallViewStateManagerService,
        private callStateManagerService: CallStateManagerService,
        private rtcService: RtcService,
        private googleTagManager: GoogleTagMangerService,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private appService: AppService,
        private utilService: UtilService,
        private leaderboardService: LeaderboardService,
        private eventsPlusService: EventsPlusService,
        private speechRecognitionService: SpeechRecognitionService,
        private jmTranslateService: JmTranslateService,
        private transcriptDataService: TranscriptDataService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    async toggleMicStatus(participant, meetingObj, source: any = null) {
        this.videoService = this.videoWrapperService.getVideoService();
        this.meetingObj = meetingObj;
        if (this.videoService.microphones?.length !== 0 && !this.videoService.noAudioPermission) {
            if (!(participant?.isHost || participant?.isCoHost)) {
                if (!participant?.micStatus && participant?.isHardMute) {
                    this.toastrService.clear();
                    this.toastrService.warning(this.translateService.instant('tostrs.you_are_on_force_mute'));
                    return;
                }
            }
            if (this.videoService.processingToggleMic) return;
            try {
                this.handleSpeechRecognition(!participant?.micStatus);
                this.setAudioInRCS(participant?.participantId, !participant?.micStatus);
                await this.videoService.toggleMicPrivacy(participant?.micStatus);
            } catch (err) {
                this.handleSpeechRecognition(false);
                this.setAudioInRCS(participant?.participantId, participant?.micStatus);
                this.appLoggerService.error('Error on toggling Local audio', err);
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
                return;
            }
        } else {
            this.eventEmitterService.emit({
                type: APP_EVENTS.SHOW_PERMISSION_UI,
                data: { show: true, skipOption: false }
            });
            if (source) {
                this.videoService.sendChatMessage({
                    type: 'PrivateChat',
                    message: MEDIA_ENGINE_EVENTS.PARTICIPANT_MIC_PERMISSION_DENIED,
                    targetParticipantId: source?.id,
                    targetParticipantName: source?.name,
                    agoraShareUid: ''
                });
            }
        }
    }

    handleSpeechRecognition(start) {
        try {
            if (!this.jmTranslateService.enableJmTranslateService && !this.transcriptDataService.enableTranscription)
                return;
            if (start) {
                this.speechRecognitionService.startSpeechRecognition();
            } else {
                this.speechRecognitionService.stopSpeechRecognition();
            }
        } catch (err) {
            this.appLoggerService.error('Error on toggling speech recognition', err);
        }
    }

    async toggleCameraStatus(participant, meetingObj, source: any = null) {
        this.videoService = this.videoWrapperService.getVideoService();
        // let prevCameraStatus = source === 'streamMixer' ? false : participant?.cameraStatus;
        let prevCameraStatus = participant?.cameraStatus || false;
        this.meetingObj = meetingObj;
        if (!this.hasDevicesAndPermissionForCamera(source)) return;
        if (!(participant?.isHost || participant?.isCoHost)) {
            if (
                !participant?.cameraStatus &&
                (this.callViewStateManagerService.roomStatus.isHardVideoMute || participant?.isHardVideoMute)
            ) {
                this.toastrService.clear();
                this.toastrService.warning(this.translateService.instant('tostrs.you_are_on_force_video_mute'));
                return;
            }
        }
        try {
            // await this.handleCustomStreamSettings();
            this.setVideoInRCS(participant?.participantId, !prevCameraStatus, this.meetingObj);
            await this.videoService.toggleCameraPrivacy(source && source === 'streamMixer' ? false : prevCameraStatus);
        } catch (err) {
            this.setVideoInRCS(participant?.participantId, prevCameraStatus, this.meetingObj);
            this.appLoggerService.error('Error on toggling Local video', err);
            this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
            return;
        }
    }

    hasDevicesAndPermissionForCamera(source = null) {
        if (this.videoService.cameras?.length === 0 || this.videoService.noCameraPermission) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.SHOW_PERMISSION_UI,
                data: { show: true, skipOption: false }
            });
            if (source) {
                this.videoService.sendChatMessage({
                    type: 'PrivateChat',
                    message: MEDIA_ENGINE_EVENTS.PARTICIPANT_CAMERA_PERMISSION_DENIED,
                    targetParticipantId: source?.id,
                    targetParticipantName: source?.name,
                    agoraShareUid: ''
                });
            }
            return false;
        }
        return true;
    }

    async handleStopCustomStream(source: 'camera' | 'screen') {
        this.videoService = this.videoWrapperService.getVideoService();
        // videoService is already assigned
        if (source === 'camera') {
            /**
             * Transfer custom stream to only screen share.
             * Update camera server state to false.
             * Stop Camera stream capture.
             * Unpublish Existing Screen share with custom stream.
             * Use existing screen share stream to create new track and publish
             */
            await this.updateLocalParticipantCameraStateOnCustomStream(true);
            this.videoService.enableStreamMixer = false;
            this.showCustomVideoFeedSettings = false;
            await this.videoService.stopCustomStreamOnDisableCamera();
            // await this.updateServerScreenShareState();
            // await this.updateLocalParticipantCameraStateOnCustomStream(true);
        } else {
            /**
             * Stop Everything. Screen share and camera.
             * Create a new camera flow.
             */
            await this.updateServerScreenShareState(false);
            // update server screen share state to false
            // this.toggleScreenShareMediaState(res, screenSharingStatus, deleteScreenTrack);
            // Need to nullify the stream?
            await this.videoService.toggleWindowShare(false, true);
            if (this.rtcService.isJMMeeting) {
                await this.videoService.stopBackUpScreenShareStream();
            }
            this.showCustomVideoFeedSettings = false;
            this.videoService.enableStreamMixer = false;
            // Stop screen share stream in channel
            // Delete screen share track
            // await this.toggleCameraStatus(
            //     this.roomConnectionService.getRoomInfo()?.localParticipant,
            //     this.meetingObj
            // );
            await this.videoService.toggleCameraPrivacy(false);
        }
    }

    async handleToggleCamera() {
        /**
         * Method to handle Camera toggle (Server state and stream publish)
         * Ask if user prefers to mix Camera feed with screen share stream.
         */
        this.videoService = this.videoWrapperService.getVideoService();
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();

        if (this.videoService.enableStreamMixer) {
            this.handleStopCustomStream('camera');
            return;
        }
        if (
            this.videoService.cameraState ||
            !this.videoService.screenSharing ||
            (this.videoService.screenSharing && !(await this.askForCustomStream()))
        ) {
            try {
                await this.toggleCameraStatus(
                    this.roomConnectionService.getRoomInfo()?.localParticipant,
                    this.meetingObj
                );
            } catch (err) {
                throw err;
            }
        }
    }

    async handleCustomStreamSettings() {
        // On turning off camera, check for custom stream and toggle screen Share layout
        return new Promise(async (resolve, reject) => {
            this.videoService = this.videoWrapperService.getVideoService();
            if (this.videoService.cameraState && this.videoService.screenSharing) {
                // await this.videoService.startScreenCall();
                if (this.videoService.streamsMixer) {
                    await this.videoService.streamsMixer.stopExistingMediaStreams(true);
                    await this.videoService.streamsMixer.resetVideoStreams();
                }
                this.videoService.disableCustomStream();
                this.showCustomVideoFeedSettings = false;
                await this.updateServerScreenShareState(true);
                await this.videoService.toggleWindowShare(false, true);
                resolve(true);
            } else {
                resolve(true);
            }
        });
    }

    async stopScreenShare() {
        this.videoService = this.videoWrapperService.getVideoService();
        if (this.videoService.enableStreamMixer) {
            this.handleStopCustomStream('screen');
            return;
        }
        this.showCustomVideoFeedSettings = false;
        this.videoService = this.videoWrapperService.getVideoService();
        await this.videoWrapperService.getVideoService().toggleWindowShare(false, true);
        if (this.rtcService.isJMMeeting) {
            await this.videoWrapperService.getVideoService().stopBackUpScreenShareStream();
        }
    }

    // stopCustomScreenShareAndFallbackto

    setAudioInRCS(participantId, micStatus, meetingObjValue = null) {
        this.meetingObj = this.meetingObj ?? meetingObjValue;
        this.roomConnectionService
            .setAudio(this.meetingObj.jiomeetId, this.meetingObj.room_id, participantId, micStatus)
            .subscribe(
                (res) => {
                    this.diagnosticService
                        .sendEvent({
                            eventAction: micStatus ? 'Mute' : 'Unmute',
                            eventCategory: 'Inside Call',
                            eventType: 'app_event',
                            status: 'success'
                        })
                        .subscribe();
                    if (!micStatus) this.handleUserAudioLevelChange(0, true);
                    this.getRoomConnectionStatus();
                },
                (err) => {
                    this.appLoggerService.error(
                        'Error while updating Mic status of local participant in Room Connection status',
                        err
                    );
                }
            );
    }

    setVideoInRCS(participantId, cameraStatus, meetingObjValue, customStreamShare = false) {
        this.meetingObj = meetingObjValue;
        this.roomConnectionService
            .setVideo(
                this.meetingObj.jiomeetId,
                this.meetingObj.room_id,
                participantId,
                cameraStatus,
                customStreamShare
            )
            .subscribe(
                (res) => {
                    this.diagnosticService
                        .sendEvent({
                            eventAction: cameraStatus ? 'Video Start' : 'Video Stop',
                            eventCategory: 'Inside Call',
                            eventType: 'app_event',
                            status: 'success'
                        })
                        .subscribe();
                    this.getRoomConnectionStatus();
                },
                (err) => {
                    this.appLoggerService.error(
                        'Error while updating Camera status of local participant in Room Connection status',
                        err
                    );
                }
            );
    }

    getRoomConnectionStatus() {
        this.roomConnectionService
            .getMeetingParticipants(this.meetingObj.jiomeetId, this.meetingObj.room_id, this.meetingObj.userPIN)
            .subscribe();
    }

    softMuteAll(meetingObjValue, source = null) {
        this.meetingObj = meetingObjValue;
        this.videoService = this.videoWrapperService.getVideoService();
        this.roomConnectionService
            .muteUnmuteAudioAll({
                jiomeetId: this.meetingObj.jiomeetId,
                roomID: this.meetingObj.room_id,
                hard: false,
                audio: false
            })
            .subscribe(
                () => {
                    this.videoService.toggleMicPrivacy(true);
                    this.videoService.sendChatMessage({
                        type: 'PublicChat',
                        message: MEDIA_ENGINE_EVENTS.HOST_STOP_AUDIO
                    });
                    if (source == 'cloud-preview') {
                        this.toastrService.success('All participants mic has been soft muted');
                    }
                },
                (err) => {
                    this.appLoggerService.error(
                        'Error while applying Audio Soft mute for all participants on playing Cloud video',
                        err
                    );
                }
            );
    }

    handleStopCloudPlayer(payload) {
        this.webinarConfService.shareMedia(payload).subscribe();
    }

    handleStopScreenShare(meetingObj) {
        const localParticipant = this.videoWrapperService.getVideoService().getLocalParticipant();
        const sharingParticipantId = this.callViewStateManagerService.roomStatus?.isSharing;
        if (sharingParticipantId == localParticipant.id) {
            return new Promise((resolve, reject) => {
                this.roomConnectionService
                    .toggleShareScreen(meetingObj?.jiomeetId, localParticipant.id, 'stop')
                    .subscribe(
                        async () => {
                            this.callStateManagerService.getRoomConnectionStatus();
                            await this.stopScreenShare();
                            resolve(true);
                        },
                        (err) => {
                            this.appLoggerService.error('Error while stopping Screenshare', err);
                            resolve(false);
                        }
                    );
            });
        } else if (this.callViewStateManagerService.isHost) {
            this.sendHostStopShareRtmMessage(this.callViewStateManagerService.roomStatus.isSharing);
        }
    }

    async proceedWithStartScreenShare(onlyPublishTrack = false) {
        this.leaderboardService.hideOverlayOnShareMedia();
        this.videoService = this.videoWrapperService.getVideoService();
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        this.callViewStateManagerService.roomStatus = this.roomConnectionService.getRoomInfo();
        // await this.createAndPublishScreenShareStream();
        // await this.videoService.getScreenCapture();
        await this.videoService.createScreenShareTrack();
        await this.updateServerScreenShareState(true);
        await this.videoService.toggleWindowShare(true, false);
        this.callStateManagerService.getRoomConnectionStatus();
        if (this.meetingObj?.webinarOptions.customization.isBrowserNotificationEnabled) {
            this.toastrService.info(
                'Adjust your Focus Mode and app notifications to ensure alerts are visible during fullscreen presentations.',
                'Notification Settings',
                {
                    toastClass: 'custom-toast-width'
                }
            );
        }
    }

    async createAndPublishScreenShareStream() {
        if (!this.rtcService.isAgoraMeeting) return;
        this.toastrService.info(this.translateService.instant('tostrs.share_screen_message'));
        this.videoService
            .createScreenShareTrack()
            .then(async () => {
                this.handleGtmEventForShareScreenStart();
                // await this.updateServerScreenShareState(true, false);
                await this.videoService.startScreenCall();
            })
            .catch((err) => {
                // TODO show error
                this.toastrService.clear();
                if (err.code === 'PERMISSION_DENIED') {
                    if (err.message?.toLowerCase().indexOf('system') > -1) {
                        this.toastrService.error(
                            this.translateService.instant('tostrs.screen_share_permission_denied')
                        );
                    }
                } else {
                    this.toastrService.error(this.translateService.instant('tostrs.screen_share_unknown_error'));
                }
            });
        // return new Promise((resolve, reject) => {})
    }

    handleGtmEventForShareScreenStart() {
        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
        this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.SHARE_SCREEN_START, this.gtmMeetingInfo);
    }

    async updateServerScreenShareState(screenSharingStatus = false) {
        return new Promise((resolve, reject) => {
            this.roomConnectionService
                .toggleShareScreen(
                    this.meetingObj.jiomeetId,
                    this.callViewStateManagerService.roomStatus?.localParticipant?.participantUri,
                    screenSharingStatus ? 'start' : 'stop'
                )
                .subscribe(
                    (res: any) => {
                        this.sendDiagnosticServiceEventForScreenShareSuccess();
                        if (screenSharingStatus) this.throwSafariScreenShareError();
                        // if (!onlyState) this.toggleScreenShareMediaState(res, screenSharingStatus, deleteScreenTrack);
                        this.videoService = this.videoWrapperService.getVideoService();
                        if (
                            (this.rtcService.isAgoraMeeting || this.rtcService.isJMMeeting) &&
                            res?.agoraScreenShareInfo
                        ) {
                            this.videoService.screenClientToken = res?.agoraScreenShareInfo.agoraToken;
                            this.videoService.screenClientUid = res?.agoraScreenShareInfo.agoraUid;
                        }
                        resolve(true);
                    },
                    (err) => {
                        console.log(err);
                        this.toastrService.error(this.translateService.instant('tostrs.screen_share_unknown_error'));
                        // reject(err);
                        resolve(false);
                    }
                );
        });
    }

    toggleScreenShareMediaState(res, screenSharingStatus, deleteScreenTrack = false) {
        this.videoService = this.videoWrapperService.getVideoService();
        if (this.rtcService.isAgoraMeeting || this.rtcService.isJMMeeting) {
            this.videoService.screenClientToken = res?.agoraScreenShareInfo.agoraToken;
            this.videoService.screenClientUid = res?.agoraScreenShareInfo.agoraUid;
        }
        this.videoService.toggleWindowShare(screenSharingStatus, deleteScreenTrack);
    }

    sendDiagnosticServiceEventForScreenShareSuccess() {
        this.diagnosticService
            .sendEvent({
                eventAction: 'Screen Share Inside Call',
                eventCategory: 'Inside Call',
                eventType: 'app_event',
                status: 'success'
            })
            .subscribe();
    }

    throwSafariScreenShareError() {
        if (this.utilService.isSafariBrowser()) {
            this.toastrService.info(this.translateService.instant('tostrs.safari_share_screen_errpor_message'));
        }
    }

    checkCustomStreamFeatureAccess() {
        const enableCustomStream = this.utilService.enablePaymentFeature(this.userService.getUserSync()?.isOTT)
            ? this.userService.getUserSync()?.eventsFeaturesEnabled?.enableCustomStream
            : this.appService.getConfigVariable('enableCustomStreamForAll') ||
              this.userService.getUserSync()?.webinarBetaAccess?.enableCustomStream;
        this.videoService = this.videoWrapperService.getVideoService();
        const canUserCustomStream =
            (enableCustomStream || this.userService.getUserSync()?.eventsFeaturesEnabled?.enableCustomStream) &&
            !this.appService.getConfigVariable('DISABLE_CUSTOM_STREAM_FEATURE');
        this.videoService.canUserCustomStream = canUserCustomStream;
        return canUserCustomStream;
    }

    askForCustomStream() {
        // Custom stream option gets enabled on second action between Camera and screen share toggle
        if (this.eventsPlusService.showStudioHost || this.eventsPlusService.showCustonLayoutSpeakerSide) {
            // removing prezzi popup for studio
            return false;
        }
        this.videoService = this.videoWrapperService.getVideoService();
        if (!this.checkCustomStreamFeatureAccess()) return;
        if (!this.hasDevicesAndPermissionForCamera()) return;
        if (this.callStateManagerService?.networkingRoom) return;
        // Confirm the user if they want to mix camera and screen share streams into single as camera is already on.
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: 'Do you want to combine Screen share and your camera feed into a single stream?',
                header: 'Publish Custom Stream',
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                acceptLabel: 'Yes',
                rejectLabel: `No`,
                accept: async () => {
                    try {
                        this.resetCustomStreamLayout();
                        if (!this.videoService.localScreenShareStream) {
                            await this.videoService.getScreenCapture();
                        }
                        // TODO: To optimise we can get camera Stream locally and use the track instead of relying on agora
                        await this.turnOffCamera();
                        // await this.stopScreenShareStream();
                        this.startCustomStream();
                    } catch (err) {
                        if (err?.name === 'NotAllowedError') return;
                        this.toastrService.error(this.translateService.instant('tostrs.screen_share_unknown_error'));
                    }
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    resetCustomStreamLayout() {
        if (!this.videoService.streamsMixer) return;
        this.videoService.changeStreamMixerLayout(0);
    }

    async stopScreenShareStream() {
        await this.videoWrapperService.getVideoService().toggleWindowShare(false);
    }

    async startCustomStream() {
        /**
         * Proceed with Screen share and Camera Stream mixing
         */
        this.videoService = this.videoWrapperService.getVideoService();
        this.videoService.streamMixerType = 'screen';
        this.videoService.enableStreamMixer = true;
        // if (!this.callViewStateManagerService.roomStatus?.isSharing) {
        //     await this.updateServerScreenShareState(true, true);
        // } else {
        //     this.stopScreenShareStream();
        // }
        try {
            // await this.toggleCameraStatus(
            //     this.callViewStateManagerService.roomStatus?.localParticipant,
            //     this.meetingObj,
            //     'streamMixer'
            // );
            // TODO: gracefully delete the existing localcameratrack
            let didScreenShareExist = await this.turnOffIndependentScreenShare();
            if (!this.rtcService.isJMMeeting) this.videoService.localCameraTrack = null;
            setTimeout(async () => {
                await this.proceedWithStartScreenShare(didScreenShareExist);
                await this.updateLocalParticipantCameraStateOnCustomStream(false);
            }, 200);
            this.showCustomVideoFeedSettings = true;
        } catch (err) {
            throw err;
        }
    }

    updateLocalParticipantCameraStateOnCustomStream(disableCamera) {
        let localParticipant = this.roomConnectionService.getLocalParticipant();
        this.videoService.cameraState = false;
        this.setVideoInRCS(
            localParticipant?.participantId,
            !disableCamera,
            this.meetingObj,
            !disableCamera && this.videoService.enableStreamMixer
        );
    }

    async turnOffCamera() {
        this.videoService = this.videoWrapperService.getVideoService();
        // if (!this.videoService.cameraState) return;
        // await this.videoService.toggleCameraPrivacy(true);
        // we also need to unpublish the camera track
        await this.videoService.unpublishLocalCameraTrack();
    }

    async turnOffIndependentScreenShare() {
        /**
         * Returns true if any screen share track already existed.
         */
        if (this.videoService.localScreenTrack) {
            await this.videoService.unpublishCustomScreenShareTrack([this.videoService.localCameraTrack]);
            this.videoService.localScreenTrack = null;
            this.videoService.localScreenShareStream = null; // JM-media stopping the media stream
            return true;
        }
        return false;
    }

    async startScreenShare() {
        /**
         * Method to handle screen share (Server state and stream publish)
         * Ask if user prefers to mix Camera feed with screen share stream.
         * This function should be called only for start screen share.
         */
        this.videoService = this.videoWrapperService.getVideoService();
        if (!this.videoService.cameraState || !(await this.askForCustomStream())) {
            this.proceedWithStartScreenShare(false);
        }
    }

    sendHostStopShareRtmMessage(targetParticipantId = null) {
        if (!targetParticipantId) {
            targetParticipantId = this.roomConnectionService.getRoomInfo().isSharing;
        }
        if (targetParticipantId === '') return;
        this.videoWrapperService.getVideoService().sendChatMessage({
            type: 'PrivateChat',
            message: MEDIA_ENGINE_EVENTS.HOST_STOP_SHARE,
            targetParticipantId
        });
    }

    handleUserAudioLevelChange(volume = 0, forceUpdate = false) {
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        const payload = {
            jiomeetId: this.meetingObj.jiomeetId,
            roomID: this.meetingObj.room_id || this.meetingObj.roomId,
            roomPin: this.meetingObj.userPIN || this.meetingObj.pin,
            participantId: this.roomConnectionService.getLocalParticipant()?.participantId,
            audioLevel: volume
        };
        if (this.appService.getConfigVariable('use_audio_level_requests_optimisation')) {
            this.sendLatestUserAudioLevel(payload, forceUpdate);
        } else {
            this.roomConnectionService.sendAudioLevel(payload).subscribe();
        }
    }

    sendLatestUserAudioLevel(payload, forceUpdate) {
        if (!this.videoService.micState && !forceUpdate) return;
        this.roomConnectionService.sendAudioLevel(payload).subscribe();
    }
}
